import { render, staticRenderFns } from "./BPMBuilder.vue?vue&type=template&id=5bac2cec&scoped=true"
import script from "./BPMBuilder.vue?vue&type=script&lang=js"
export * from "./BPMBuilder.vue?vue&type=script&lang=js"
import style0 from "bpmn-js/dist/assets/bpmn-js.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "bpmn-js/dist/assets/diagram-js.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "./BPMBuilder.vue?vue&type=style&index=3&id=5bac2cec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bac2cec",
  null
  
)

export default component.exports